const mqtt = require('mqtt')
import {mqttUrl} from './common'
export const host = `wss://${mqttUrl}/mqtt`

export const topicPrefix = '/ypt/u_'
export const topics = {
  add_vh: 'add_vh',
  mq_remote_vh_black_add: 'mq_remote_vh_black_add', // 增加黑名单
  mq_query_vh_list: 'mq_query_vh_list',
  mq_query_charge_list: 'mq_query_charge_list',
  mq_charge_success: 'mq_charge_success',
  mq_query_charge_info: 'mq_query_charge_info', // web subscribe
  mq_vh_inout_pnull_in: 'mq_vh_inout_pnull_in', // web subscribe
  mq_remote_open_gate: 'mq_remote_open_gate', // web subscribe
  mq_query_inout_list: 'mq_query_inout_list', // web subscribe
  mq_query_inout_detail: 'mq_query_inout_detail', // web subscribe
  mq_query_device_list: 'mq_query_device_list', // web subscribe
}
export const Client = function () {
  if (typeof Client.instance === 'object') {
    return Client.instance;
  }
  let clientID = 'h5-' + new Date().getTime() + Math.random().toString().substr(3, 5)
  let options = {
    protocolVersion: 4,
    clientId: clientID,
    // path: '/mqtt',
    username: 'username',
    password: 'password',
    reconnectPeriod: 3000,
    connectTimeout: 5000,
    reschedulePings: true,
    resubscribe: false,
    keepalive: 30,
    // useSSL: config.useSSL || true,
  }

  this.client = mqtt.connect(host, options);
  Client.config = {}
  Client.subscribeTopics = []
  this.client.on('connect', connack => {
    console.log('connect', connack);
    Client.subscribeTopics.length && this.client.subscribe(Client.subscribeTopics, {qos: 0}, function (err, granted) {
      console.log(err, granted)
    })
  })
  this.client.on('reconnect', connack => {
    console.log('server-local-connect', connack);
    Client.subscribeTopics.length && this.client.subscribe(Client.subscribeTopics, {qos: 0}, function (err, granted) {
      console.log(err, granted)
    })
  })

  this.client.on('message', (topic, message, packet) => {
    console.log('messange', message && message.toString(), packet)
    if (message) {
      let msg = message.toString()
      if (/{.*}/.test(msg)) {
        msg = JSON.parse(msg)
        console.log('sessionStorage.getItem(\'request_remote_uid\')', sessionStorage.getItem('request_remote_uid'))
        console.log('msg.uid', msg.uid)
        if (msg.uid) {
          if (msg.uid != sessionStorage.getItem('request_remote_uid')) {
            console.log('不是一个会话的uid,舍弃', msg)
            return
          }
        } else {
          console.log('pls没有传uid,忽略uid', msg)
        }
        console.log('Client.config', Client.config)
        typeof Client.config[msg.topic] === 'function' && Client.config[msg.topic](msg)
      }
    }
  })

  this.client.on('error', function (error) {
    console.log('server-loca-error', error);
  });
  this.client.on('disconnect', function (error) {
    console.log('disconnect', error);
  });
  Client.instance = this;
  return this;
}

export const subscribe = function (topic, options) {
  return new Promise(function (resolve, reject) {
    let client = new Client().client
    let opt = options || {
      qos: 0
    }
    client.subscribe(topic, opt, function (err, granted) {
      if (err) {
        if (err && err.message == 'client disconnecting') {
          return reject({
            code: 99999,
            msg: '连接失效，请刷新页面'
          })
        }
        return reject(err)
      }
      if (!Client.subscribeTopics.includes(topic)) Client.subscribeTopics.push(topic)
      console.log(granted)
      resolve(granted)
    })
  })
}

export const unsubscribe = function (topic) {
  return new Promise(function (resolve, reject) {
    let client = new Client().client
    console.log('topic', topic);
    client.unsubscribe(topic, function (err) {
      if (err) {
        if (err && err.message == 'client disconnecting') {
          window.location.reload()
        }
        return reject(err)
      }
      let index = Client.subscribeTopics.indexOf(topic)
      if (index !== -1) Client.subscribeTopics.splice(index, 1)
      resolve()
    })
  })
}

export const initMQTT = async function  (cb) {
  try {
    let client = new Client().client
    if (client && client.connected) {
      typeof cb === 'function' && cb(client)
      return client
    } else {
      await Promise.reject('NetWork err!')
    }
  } catch (e) {
    throw e
  }
}

export const clearSelf = function () {
  return new Promise(function (resolve, reject) {
    let client = new Client().client
    Client.config = {}
    if (Array.isArray(Client.subscribeTopics) && Client.subscribeTopics.length) {
      client.unsubscribe(Client.subscribeTopics, function (err) {
        if (err) return reject(err)
        resolve()
      })
    } else {
      resolve()
    }
  })
}



